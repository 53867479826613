

































import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SessionConfigurationStep, { StepState } from './SessionConfigurationStep.vue';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';

export enum StepNumber {
  Session = 1,
  Enrollment = 2,
  SchedulesAndPricing = 3,
  Review = 4,
}

@Component({
  components: {
    SessionConfigurationStep,
  }
})
export default class SessionConfigurationStepper extends Vue {
  public readonly StepState = StepState;
  public readonly StepNumber = StepNumber;
  public readonly screenText = new ScreenText();
  APP_CONST = APP_CONST;

  @Prop({ type: Number as PropType<StepNumber>, required: true }) readonly activeStep!: StepNumber;

  get sessionStepTitle(): string {
    return this.screenText.getScreenText('SESSION_CONFIGURATION_STEP_SESSION_TITLE');
  }

  get enrollmentStepTitle(): string {
    return this.screenText.getScreenText('SESSION_CONFIGURATION_STEP_ENROLLMENT_TITLE');
  }

  get schedulesAndPricingTitleOne(): string {
    return this.screenText.getScreenText('SESSION_CONFIGURATION_STEP_SCHEDULES_AND_PRICING_TITLE_1');
  }

  get schedulesAndPricingTitleTwo(): string {
    return this.screenText.getScreenText('SESSION_CONFIGURATION_STEP_SCHEDULES_AND_PRICING_TITLE_2');
  }

  get reviewStepTitle(): string {
    return this.screenText.getScreenText('SESSION_CONFIGURATION_STEP_REVIEW_TITLE');
  }

  public getState(step: StepNumber): StepState {
    if (step < this.activeStep) {
      return StepState.Complete;
    }
    else if (step == this.activeStep) {
      if (step == StepNumber.Review) {
        // "Review" step is special -- should be marked complete when active.
        return StepState.Complete;
      }
      else {
        return StepState.Active;
      }
    }
    else {
      return StepState.Inactive;
    }
  }
}

